.swal2-modal,
.swal2-popup {
    min-width: 30%;
    font-size: 1rem;
    font-weight: 500;
    border: 2px solid #93c6fd !important;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.swal2-modal.swal2-toast,
.swal2-popup.swal2-toast {
    zoom: 1.8;
    border: 1px solid grey !important;
    font-size: 10px !important;
    margin-top: 22% !important;
    box-shadow: none !important;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.swal2-header {
    word-break: normal;
}

.swal2-actions button {
    padding: 5px 15px;
}
.swal2-actions button.swal2-cancel {
    background-color: #a94a53;
}