.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.clickable {
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bottom-btn {
  position: absolute !important;
  bottom: 10%;
  right: 10%;
}

.hint-class {
  font-size: 0.75rem;
  font-style: italic;
}

.filter-section {
  padding-bottom: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.75) 3px 3px 3px 0px;
}

.key-header {
  margin: auto;
  font-size: 1rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fixed-container {
  height: 400px;
  overflow-y: auto;
}

.App-link {
  color: #61dafb;
}

.add-input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.app-btn {
  display: inline-block;
  font-weight: 400;
  margin: 0.25rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  /* color: #fff; */
  /* background-color: #17a2b8; */
  /* border-color: #17a2b8; */
  border: 1px solid transparent;
  padding: 0.2rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

app-btn.disabled,
.app-btn:disabled {
  background-color: #6b6c6d !important;
  border-color: #6b6c6d !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.projectTitle {
  font-size: 21px;
  font-weight: 800;
  padding: 1rem 0.5rem;
}

.pull-right {
  float: right !important;
}

.clearfix {
  clear: both;
}

.full-height {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container {
  padding: 3rem;
  min-width: 30rem;
  border: 1px solid #ccc;

  box-shadow: 0.1rem 0.1rem 0.5rem #9a9a9a;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.translation-key {
  color: #016c96;
}

.modal-top-aligned .modal-dialog {
  position: relative;
  bottom: 20%;
}

.nav-z-index {
  z-index: 1099;
}

.btn-border-right {
  border-right: 1px solid rgba(0, 0, 0, 0, 0.5);
}

.click-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.title {
  font-size: 1.5em;
  text-transform: capitalize;
  font-weight: 500;
}

pre.code {
  background-color: #444;
  color: #fff;
  padding: 10px;
  height: 80vh;
  overflow: auto;
}

.btn.btn-xs {
  padding: 0.15rem 0.3rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.text-bold {
  font-weight: bolder;
}

.list-group-flush > .list-group-item {
  border: none;
  padding: 5px;
  border-left: 1px solid #000;
}

.list-group-flush > .list-group-item:last-child,
.list-group-flush > .list-group-item:first-child {
  /* border: none; */
  line-height: 1.5;
}

.list-group-flush > .list-group-item::before {
  content: "---";
  letter-spacing: -2px;
  margin-left: -6px;
  margin-right: 5px;
}

.float-top {
  position: relative;
  top: 0;
  right: 0;
}

.btn.float-right {
  margin-right: 0.3rem;
}

.h1 {
  font-size: 1.6rem;
}

.h2 {
  font-size: 1.5rem;
}

.h3 {
  font-size: 1.4rem;
}

.h4 {
  font-size: 1.3rem;
}

.h5 {
  font-size: 1.2rem;
}

.h6 {
  font-size: 1.1rem;
}