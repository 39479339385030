.notification-main {
    position: absolute !important;
    bottom: 0;
    left: 0;
    width: 100%;
}

.notification-error {
    background-color: #D8000C !important;
    max-width: 100% !important;
}

.notification-success {
    background-color: #4F8A10 !important;
    max-width: 100% !important;
}

.notification-text {
    text-align: center;
    color: #fff;
}
